import React, { useState, forwardRef, useRef, useImperativeHandle, useEffect } from 'react'
import { Link } from 'react-router-dom';
import { Modal, Button, Form } from 'react-bootstrap';
import { socialLogin, login, closeAuthModal, openAuthModal } from '../../stores/actions/auth-action'
import { useSelector, useDispatch } from 'react-redux';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import InstagramLogin from 'react-instagram-login';
//import { LinkedIn } from 'react-linkedin-login-oauth2';
import { toast } from 'react-toastify';
import LinkedIn from '../../reuseables/linked-in';


const Login = (props) => {
	const GOOGLE_CLIENT_ID = '80926907619-h8beld3ufkm88nm77ot37fr596s3gvlo.apps.googleusercontent.com'
	const FACEBOOK_APP_ID = '392514378432239'
	const INSTRAGRAM_ID = '639972370224065'
	const [isLoading, setIsLoading] = useState(false)
	const LINKEDIN_CLIENT = '86fyutfylwp9gi'
	const { showLogin } = props;
	const emailRegex = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
	const [form_error, setFormError] = useState({});
	const dispatch = useDispatch();
	const [loginForm, setLoginForm] = useState({
		name: '',
		pass: ''
	})
	const loginOpen = useSelector(state => state.auth.loginOpen);


	/* useEffect(() => {
		return () => {
			
		}
	}, [loginOpen]) */

	const handleClose = () => {
		let errors = {};
		setFormError({ ...form_error, errors });
		dispatch(closeAuthModal())
	}

	const clearForm = () => {
		setLoginForm({
			name: '',
			pass: ''
		})
	}

	const handleInput = (e) => {
		setLoginForm({ ...loginForm, [e.target.name]: e.target.value })
		if (e.target.value && form_error.errors && Object.entries(form_error.errors).length > 0 && Object.entries(form_error.errors).find(obj => obj[0] === e.target.name)) {
			let errors = {};
			Object.entries(form_error.errors).filter(obj => obj[0] !== e.target.name).map(obj => errors[obj[0]] = obj[1]);
			setFormError({ ...form_error, errors });
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		let errors = {};
		setFormError({ ...form_error, errors });
		let isValid = true;
		let formArr = Object.keys(loginForm).map(obj => ({ key: String(obj), value: loginForm[obj] }));
		if (formArr && formArr.length) {
			formArr.forEach(obj => {
				if (obj.value === '') {
					errors[obj.key] = `This is required field`;
					setFormError({ ...form_error, errors });
					isValid = false;
				}
			});

		}

		if (isValid) {
			setIsLoading(true)
			await dispatch(login(loginForm)).then(resp => {
				setIsLoading(false)
				if (!resp.error) {
					clearForm()
					handleClose();
					setUserDataToStore(resp, 'normal')
				} else {
					toast.error(resp.msg,
						{
							position: toast.POSITION.TOP_RIGHT,
							autoClose:3000
						});
				}
			});
		}
	}

	const responseFacebook = (response) => {
		if (response && response.id) {
			setIsLoading(true)
			const loginData = {
				id: response.id,
				name: response.name,
				email: response.email,
				picture: response.picture.data.url,
				social_type: 'facebook',
			}
			//setLoaded(true)           
			dispatch(socialLogin(loginData)).then(res => {
				setIsLoading(false)
				setUserDataToStore(res, 'social')
			})
		}
	}

	const responseGoogle = (response) => {
		if (response && response.profileObj) {
			setIsLoading(true)
			let user = response.profileObj
			const loginData = {
				id: user.googleId,
				name: user.name,
				email: user.email,
				photoUrl: user.imageUrl,
				social_type: 'google'
			}
			dispatch(socialLogin(loginData)).then(res => {
				setIsLoading(false)
				setUserDataToStore(res, 'social')
			});
		}
	}

	const responseLinkedIN = (response) => {
		alert()
		console.log(response)
		/* if (response && response.profileObj) {
		  let user = response.profileObj
		  const loginData = {
			//id: user.googleId,
			name: user.name,
			email: user.email,
			photoUrl: user.imageUrl,
			social_type: 'instragram'
		  }
		  dispatch(socialLogin(loginData)).then(res => {
			setSocialUser(res)
		  });
		} */
	}


	const openForgotModal = (e) => {
		dispatch(openAuthModal('forgot'));
	}

	const openSignUpModal = (e) => {
		dispatch(openAuthModal('resgistration'));
	}

	const setUserDataToStore = (resp, userType) => {
		let userData = resp.data;
		userData.user_type = userType
		localStorage.setItem('user', JSON.stringify(userData))
		var formArr =[];
		Object.entries(userData.form).forEach(val=>{
			if(val[0] == 'bike_destination_form' && val[1] === true){
				formArr.push({name:'destination', order:6, icon:'destinationico.png'})
			}else if(val[0] == 'bike_expert_form' && val[1] === true){
				formArr.push({name:'enthusiasts', order:4, icon:'expertico.png'})
			}else if(val[0] == 'buy_form' && val[1] === true){
				formArr.push({name:'product', order:1, icon:'productico.png'})
			}else if(val[0] == 'event_form' && val[1] === true){
				formArr.push({name:'event', order:5, icon:'eventico.png'})
			}else if(val[0] == 'smart_solutions_form' && val[1] === true){
				formArr.push({name:'solutions', order:3, icon:'smartico.png'})
			}
			
		})
		formArr.push({ name: 'services', order: 2, icon: 'destinationico.png' })
		localStorage.setItem('user_forms', JSON.stringify(formArr.sort((a,b)=>(a.order - b.order) )))
		localStorage.setItem('csrf_token', JSON.stringify(resp.csrf_token))
		toast.success('Welcome to carryon.bike platform. Enjoy the ride through our platform 🙂',
			{
				position: toast.POSITION.TOP_RIGHT,
				autoClose:3000
			});
		handleClose();
		
	}

	return (
		<Modal className="cmnmodal logmodal" size="lg" centered show={loginOpen} onHide={handleClose}>
			<Modal.Header closeButton>
				<Modal.Title className="logholder">

				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className="img">
					<img src={require('../../assets/images/login.jpg')} alt="" />
				</div>
				<div className="logholder">
					<div>
						<Link className="logo" to="#">
							<img src={require('../../assets/images/logo.png')} alt="" />
						</Link>
						<h3>Login with</h3>
						<ul className="logsocial">
							<li>
								<FacebookLogin
									appId={FACEBOOK_APP_ID} //APP ID NOT CREATED YET
									fields="name,email,picture"
									callback={responseFacebook}
									render={renderProps => (
										<Link onClick={renderProps.onClick} to='#'><img src={require("../../assets/images/fb.png")} alt="" /></Link>
									)}
								/>
							</li>
							<li>
								<GoogleLogin
									clientId={GOOGLE_CLIENT_ID}
									render={renderProps => (
										<Link onClick={renderProps.onClick} to='#' disabled={renderProps.disabled}><img src={require('../../assets/images/ggl.png')} alt="" /></Link>
									)}
									onSuccess={responseGoogle}
									onFailure={responseGoogle}
									cookiePolicy={'single_host_origin'}
								/>
							</li>
							<li>
								{/* <LinkedIn
									clientId={LINKEDIN_CLIENT}
									onFailure={responseLinkedIN}
									onSuccess={responseLinkedIN}
									redirectUri="https://pxl-loyalty.in/"
									renderElement={({ onClick, disabled }) => (
										<Link onClick={onClick} disabled={disabled} to='#'><img src={require('../../assets/images/linkdin.png')} alt="" /></Link>
									)}
								/> */}
								<LinkedIn>
									<img src={require('../../assets/images/linkdin.png')} alt="" />
								</LinkedIn>
							</li>
						</ul>
						<h4>
							<span>or log in using your carry on bike account</span>
						</h4>
						<div className="formdiv">
							<form onSubmit={handleSubmit}>
								<div className="form-group">
									<input className="mandatory" type="email" name="name" placeholder="Email" value={loginForm.name} onChange={handleInput} />
									{
										form_error['errors'] && form_error['errors']['name'] && (
											<span className='form_error'>{form_error['errors']['name']}</span>
										)

									}
								</div>
								<div className="form-group">
									<input className="mandatory" type="password" name="pass" placeholder="Password" value={loginForm.pass} onChange={handleInput} />
									{
										form_error['errors'] && form_error['errors']['pass'] && (
											<span className='form_error'>{form_error['errors']['pass']}</span>
										)

									}
								</div>
								<div className="form-group">
									<input type="submit" name="" value="login" />
								</div>
							</form>
						</div>
						<Link className="forgot" to="#" onClick={openForgotModal}>forgot password?</Link>
						<div className="dont">
							Don't have an account? 
			        			<Link to="#" onClick={openSignUpModal}>Create One</Link>
						</div>

					</div>
				</div>
			</Modal.Body>
			<div className="loaddiv loaddiv1" style={{ display: isLoading ? 'block' : 'none' }}>
				<span className="loader"></span>
			</div>
		</Modal>

	)
}

export default Login
